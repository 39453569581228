import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IHtmlHelperService } from '@pinnakl/shared/types';

export const HTML_REGEX = {
  BREAK: /<\s*br[^>]?>/,
  CHARS: /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g
};

export function getHtmlWithoutTags(html?: string): string {
  return html ? html?.replace(HTML_REGEX.BREAK, '\n').replace(HTML_REGEX.CHARS, '') : '';
}

export const CustomValidators = {
  minLengthTrimmed: (length: number) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const trimmedValue = control.value?.trim?.();
      if (trimmedValue == null) return null;
      return trimmedValue.length < length
        ? { minlength: { requiredLength: length, currentLength: trimmedValue.length } }
        : null;
    };
  },
  maxLengthTrimmed: (length: number) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const trimmedValue = control.value?.trim();
      if (!trimmedValue) return null;
      return trimmedValue.length > length
        ? { maxlength: { requiredLength: length, currentLength: trimmedValue.length } }
        : null;
    };
  },
  requiredIfValidator: (predicate: (value?: any) => boolean): ValidatorFn => {
    return (formControl: AbstractControl): ValidationErrors | null => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  },
  emptySpaceValidator: (): ValidatorFn => {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const value = formControl.value;
      if (value && value.trim().length === 0) {
        return { emptySpace: true };
      }
      return null;
    };
  },
  customValidator:
    (predicate: (value?: any) => boolean, passFormControlValue = false, error = {}): ValidatorFn =>
    (formControl: AbstractControl): ValidationErrors | null =>
      predicate(passFormControlValue ? formControl.value : undefined) ? null : error,
  nameValidators: (
    validators: ValidatorFn[] = [],
    nameConstraints = {
      minLength: 3,
      maxLength: 20,
      required: true
    }
  ): ValidatorFn | null =>
    Validators.compose([
      CustomValidators.customValidator(
        value => value?.trim().length > 0,
        true,
        nameConstraints?.required ? { required: true } : {}
      ),
      CustomValidators.customValidator(
        value => value?.trim().length >= nameConstraints.minLength,
        true,
        {
          minlength: { requiredLength: nameConstraints.minLength }
        }
      ),
      CustomValidators.customValidator(
        value => value?.trim().length <= nameConstraints.maxLength,
        true,
        {
          maxlength: { requiredLength: nameConstraints.maxLength }
        }
      ),
      ...validators
    ]),
  itemNamesValidator: (itemNamesList: string[]): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return itemNamesList.includes(control.value?.trim()) ? { viewAlreadyExists: true } : null;
    };
  },
  itemNamesValidatorCaseInsensitive: (itemNamesList: string[]): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      return itemNamesList.some(
        name => name.toLocaleLowerCase() === control.value?.trim()?.toLocaleLowerCase()
      )
        ? { viewAlreadyExists: true }
        : null;
    };
  },
  atLeastOneItemFromListIsSelected: (): ValidatorFn => {
    return (control: AbstractControl<boolean[]>): ValidationErrors | null => {
      return control?.value && !Object.values(control.value).includes(true)
        ? { atLeastOneSelected: true }
        : null;
    };
  },
  maxEntities: (maxLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      return control?.value && Object.keys(control.value).length > maxLength
        ? { maxEntities: { maxLength } }
        : null;
    };
  },
  maxEntitiesCount: (maxLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      return control?.value && Array.isArray(control.value) && control.value.length > maxLength
        ? { maxEntities: { maxLength } }
        : null;
    };
  },
  minEntities: (minLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      return control?.value && Object.keys(control.value).length < minLength
        ? { minEntities: { minLength } }
        : null;
    };
  },
  greaterThanValidator: (value: number): ValidatorFn => {
    return function (control: AbstractControl): ValidationErrors | null {
      if (typeof control.value !== 'number') return null;

      return control.value > value ? null : { greaterThan: { value: value.toString() } };
    };
  },
  htmlMaxLengthValidator: (
    maxLength: number,
    htmlHelperService: IHtmlHelperService
  ): ValidatorFn => {
    return function (control: AbstractControl): ValidationErrors | null {
      if (!control.value) return null;
      const html = control.getRawValue();
      const contentLength = htmlHelperService.getPlainText(html).length;

      return contentLength > maxLength ? { maxLength, currentLength: contentLength } : null;
    };
  },
  htmlLengthValidator: (
    validators: ValidatorFn[] = [],
    htmlConstraints = {
      minLength: 10,
      maxLength: 20000,
      required: true
    }
  ): ValidatorFn | null =>
    Validators.compose([
      CustomValidators.customValidator(
        value => getHtmlWithoutTags(value).length > 0,
        true,
        htmlConstraints?.required ? { required: true } : {}
      ),
      CustomValidators.customValidator(
        value => getHtmlWithoutTags(value).length >= htmlConstraints.minLength,
        true,
        {
          minlength: { requiredLength: htmlConstraints.minLength }
        }
      ),
      CustomValidators.customValidator(
        value => getHtmlWithoutTags(value).length <= htmlConstraints.maxLength,
        true,
        {
          maxlength: { requiredLength: htmlConstraints.maxLength }
        }
      ),
      ...validators
    ])
};
